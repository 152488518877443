<template>
  <div>
    <AuthHeader :title=" $t('auth.updatePassword.title')" />

    <Alert v-if="passwordUpdateSucceeded" type="info" :message="$t('auth.updatePassword.success')" />

    <Alert v-else-if="!hasValidQuery" type="danger" :message="$t('auth.updatePassword.invalidQueryError')" />

    <Form v-else @submit="submit">
      <Alert v-if="passwordUpdateError" type="danger" :message="passwordUpdateError" />

      <div>
        <Paragraph :text="$t('auth.updatePassword.helpText', {email})" />

        <FormField :label="$t('general.password')">
          <PasswordInputField v-model="password" :disabled="isUpdatingPassword" />
        </FormField>

        <FormField :label="$t('auth.repeatPassword')">
          <PasswordInputField v-model="confirmPassword" :disabled="isUpdatingPassword" />
          <HelpText v-if="!isPasswordsValid && password.length > 0" type="danger" :text="$t('auth.join.passNoMatch')" />
        </FormField>

        <Button
          type="secondary"
          :title="$t('auth.updatePassword.updateButton')"
          :disabled="!isPasswordsValid"
          :loading="isUpdatingPassword"
          fullwidth
          @click="submit">
        </Button>
      </div>
    </Form>

    <AuthFooter />
  </div>
</template>

<script>
import AuthHeader from '@/modules/auth/components/AuthHeader.vue'
import AuthFooter from '@/modules/auth/components/AuthFooter.vue'

export default {
  components: {
    AuthHeader,
    AuthFooter
  },

  data () {
    return {
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: '',
      confirmPassword: '',
      isUpdatingPassword: false,
      passwordUpdateError: null,
      passwordUpdateSucceeded: undefined
    }
  },

  computed: {
    hasValidQuery () {
      return this.email && this.token
    },

    isPasswordsValid () {
      return this.password.length >= 6 &&
        this.confirmPassword.length >= 6 &&
        this.password === this.confirmPassword
    }
  },

  methods: {
    async submit () {
      if (this.isUpdatingPassword) return

      this.isUpdatingPassword = true
      this.passwordUpdateError = null

      try {
        let request = {
          email: this.email,
          password: this.password,
          token: this.token
        }
        await this.$store.dispatch('auth/updatePassword', request)
        this.passwordUpdateSucceeded = true
      } catch (error) {
        if (error.status === 403) {
          this.passwordUpdateError = this.$i18n.t('auth.updatePassword.permissionError')
        } else {
          this.passwordUpdateError = this.$i18n.t('general.unknownErrorMessage')
        }
      } finally {
        this.isUpdatingPassword = false
      }
    }
  }
}
</script>
